import React from 'react';
import CategoriesList from 'src/components/categories-list';
import { MainDispensaryLayout } from 'src/components/layouts/main-dispensary-layout';

import { getStaticPaths } from 'utils/ssr';
import { generateConsumerDispensaryStaticProps } from 'src/dispensary/utils/ssr/generate-consumer-dispensary-static-props';

export { getStaticPaths };

export const getStaticProps = async (context) => generateConsumerDispensaryStaticProps(context);
export default function CategoriesPage() {
  return <CategoriesList />;
}

CategoriesPage.layout = MainDispensaryLayout;
